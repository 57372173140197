const config = {
  ENVIRONMENT_NAME: 'staging',
  forwoodId: {
    URL: 'https://id.staging.riotinto.forwoodsafety.com',
    USER_TOKEN_URL: 'https://n2g694c3v3.execute-api.us-east-1.amazonaws.com/prod'
  },
  apiGateway: {
    REGION: 'us-east-1',
    URL: 'https://mzhkhbosfi.execute-api.us-east-1.amazonaws.com/prod'
  },
  reactApp: {
    VERSION: 'admin-container-1.10.0',
    HOSTNAME: 'https://admin.staging.riotinto.forwoodsafety.com',
    COMPANY_MICROFRONTEND_URL: 'https://company.staging.riotinto.forwoodsafety.com',
    PHYSICAL_LOCATION_MICROFRONTEND_URL: 'https://locations.staging.riotinto.forwoodsafety.com'
  },
  configurationService: {
    URL: 'https://config.staging.riotinto.forwoodsafety.com',
    WEBSOCKET: 'wss://0ed2rb09ge.execute-api.us-east-1.amazonaws.com/staging'
  },
  get forwoodIdAuthUrl() {
    return `${this.forwoodId.URL}/authorize?response_type=token&scope=openid&client_id=${this.forwoodId.APP_CLIENT_ID}&redirect_uri=${this.reactApp.HOSTNAME}&state=`;
  }
};

export default config;
